@font-face {
    font-family: "Atlas Grotesk";
    src: url("/fonts/AtlasGrotesk-Regular-Web.eot");
    src: local("?"), url("/fonts/AtlasGrotesk-Regular-Web.woff") format("woff"),
        url("/fonts/AtlasGrotesk-Regular-Web.eot") format("opentype"),
        url("/fonts/AtlasGrotesk-Regular-Web.svg") format("svg");
}

@font-face {
    font-family: "Atlas Typewriter";
    src: url("/fonts/AtlasTypewriter-Regular-Web.eot");
    src: local("?"),
        url("/fonts/AtlasTypewriter-Regular-Web.woff") format("woff"),
        url("/fonts/AtlasTypewriter-Regular-Web.eot") format("opentype"),
        url("/fonts/AtlasTypewriter-Regular-Web.svg") format("svg");
}

body,
html {
    font-family: "Atlas Grotesk", Helvetica, Arial, sans-serif;
    letter-spacing: -0.02rem !important;
    font-size: 18px;
    line-height: 1.2;
}

body {
    margin: 0;
    padding: 0;
}

section {
    display: flex;
    justify-content: center;
}

video {
    margin: 60px;
    width: calc(100vw - 30px);
}

.mute {
    position: fixed;
    margin: 0;
    padding: 15px;
    right: 0;
    bottom: 0;
    background: transparent;
    border: 0;
    font-size: inherit;
    letter-spacing: -0.02rem !important;
}

.mute,
.mute:hover,
.mute:focus,
.mute:active {
    outline: none;
}
.container-fluid {
    /* padding: 60px; */
}

.header {
    padding: 15px;
    width: 100%;
    position: fixed;
    z-index: 1000;
}

header > a.logo {
    float: left;
    margin-left: 0;
}

.header > a {
    display: inline-block;
    margin-left: 30px;
    float: right;
    color: #000;
    line-height: 1;
}

.header > a:hover,
.header > a:active,
.header > a:focus {
    text-decoration: none;
    border-bottom: 0;
}

/* .info-route {
    position: fixed;
    z-index: 1000;
} */

.info {
    padding: 15px 0;

    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000 !important;
    /* top: 0; */
    /* left: 0; */
    background-color: #533f67;
    color: #998fa4;
    overflow-y: scroll;
}

.info button.active,
.header > a.active,
.info a:link,
.info a:focus,
.info a:active,
.info a:hover {
    outline: none;
    padding-bottom: 1px;
    text-decoration: none;
    border-bottom: 1px solid;
}

.info__header {
    margin-bottom: 30px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.info__lang button {
    background: transparent;
    border: 0;
    color: inherit;
    padding: 0;
    display: inline-block;
}

.info__close {
    display: flex;
    align-self: flex-end;
    right: 0;
    text-align: right;
    background: transparent;
    border: 0;
    color: inherit;
    padding: 0;
    font-size: inherit;
}

.info__close,
.info__close:hover,
.info__close:focus,
.info__close:active {
    border-bottom: 0 !important;
    color: inherit;
    text-decoration: none;
    outline: none;
}
@media (min-width: 545px) {
    video {
        margin: 60px;
        height: calc(100vh - 120px);
    }
}
@media (min-width: 1024px) {
    body,
    html {
        font-size: 30px;
    }
    .info {
        width: calc(50vw + 5px);
    }

    .info button.active,
    .header > a.active,
    .info a:link,
    .info a:focus,
    .info a:active,
    .info a:hover {
        border-bottom: 2px solid;
    }
}
